 .sidenav li a {
    font-size: 15px;
    padding-top: 0px;
}

/* The sidebar menu */
.sidenav {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 200px; 
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; 
    left: 0;
    background-color: #0079bf; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 75px;
    cursor: pointer;
}

.sidenav::-webkit-scrollbar-thumb{
    width: 5px;
}
.sidenav::-webkit-scrollbar{
    width: 5px;
}

/* The navigation menu links */
.logout,
.sidenav .blog_tag {
    padding: 18px 20px 18px 28px;
    text-decoration: none;
    font-size: 16px;
    color: #f1f1f1;
    display: block;
}
.logout:hover,
.sidenav .blog_tag:hover {
    background-color: #f1f1f1;
    color: #000 !important;
}

.blog_tag_list li a {
    padding: 5px 20px 5px 28px;
    text-decoration: none;
    font-size: 16px;
    color: #f1f1f1 !important;
    display: block;
}
/* When you mouse over the navigation links, change their color */

.blog_tag_list li a:hover {
    background-color: #f1f1f1;
    color: #000 !important;
}
