.gutter {
    margin-left: 30px;
}
.attachment-thumbnail  {
    display: block;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    line-height: 59px;
    text-align: center;
    text-decoration: none;
    width: 100%;
    background-color: #c6c6c6;
    padding: 0 18px;
}

.Neon {
   
    font-size: 14px;
    color: #494949;
    position: relative; 
}
.Neon * { 
    box-sizing: border-box;
} 
.Neon-input-choose-btn.blue {
    color: #008BFF;
    border: 1px solid #008BFF;
}
.Neon-input-choose-btn {
    display: inline-block;
    padding: 8px 14px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    color: #8d9496;
    border-radius: 3px;
    border: 1px solid #c6c6c6;
    vertical-align: middle;
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0,0,0,0.05); 
    transition: all 0.2s;
}

@media (max-width:460px){
    .attachmentpopup-shadow {
        left: 10px;
    }
}

@media (max-width:960px){
    .attachmentpopup-shadow {
        left: 210px;
    }
}