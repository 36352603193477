/* -------------------Tag Styles------------------------ */
 
.board {
  white-space: nowrap;

  height: auto;
  position: absolute;
}

/* -------------------Other Styles------------------------ */

/* Main Heading style */
.main-heading {
  
  font-weight: bold;
  font-size: 30px;
}

.jira-icon {
  color: #fdcb9e;
  font-size: 40px;
  overflow: hidden;
  transition-duration: 0.8s;
  transition-property: transform;
}

.jira-icon:hover {
  color: tomato;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

.edit-dots {
  /* color: #fdcb9e; */
  /* font-size: 40px; */
  overflow: hidden;
  transition-duration: 0.3s;
  transition-property: transform;
}

.edit-dots:hover {
  /* color: tomato; */
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

.github-icon {
  color: #fdcb9e;
  font-size: 40px;
}

.github-icon:hover {
  color: tomato;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

 

.footer-link,
.footer-link:link,
.footer-link:visited,
.footer-link:focus,
.footer-link:active {
  color: white;
  text-decoration: none;
}

.footer-link:hover {
  font-weight: bolder;
  font-size: 0.8rem;
  color: #ec823a;
}
.board {
  margin-top: 120px;
}
.paper-list {
  padding: 4% 5% 4% 5%;
  border-radius: 5px;
  height: 100%;
  box-shadow: 0px 1px 5px rgb(168, 168, 168);
}

/* Style for each card */
.task-card {
  
  cursor: pointer;
  background-color: "white";
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.task-card:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 2px 3px rgba(0, 0, 0, 0.22);
  /* background-color: "#f7fff2"; */
}
 

.bucket {
  padding: 10px 0 50px 20px;
  /* border-radius: 2%; */
}

.bucket-edit:hover {
  color: #f37121;
}

.add-task {
  background-color: rgb(253, 253, 253);
  margin-top: 3%;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.add-task:hover {
  background-color: rgb(156, 156, 156);
}
.add-task:hover .add-task-content {
  color: white;
}
.add-task-content {
  color: rgb(49, 49, 49);
}
.bucket-title {
  cursor: move;
}
.add-bucket {
  text-align: center;
  padding: 1%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-top: 2%;
  background-color: rgb(195, 195, 195);
  opacity: 0.8;
  color: black;
  cursor: pointer;
  width: 350px;
}
.add-bucket:hover {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 2px 3px rgba(0, 0, 0, 0.22);
}
.task-img {
  width: 50px;
  height: 50px;
  border: 2px solid;
}
.add-task-attachment-row {
  margin: 2%;
}
.task-card-body {
  padding: 3% 1% 3% 3%;
  word-wrap: break-word;
}
.task-card-dragged {
  background-color: rgb(255, 89, 89);
  opacity: 0.5;
  animation: shake 0.8s infinite;
}
.task-card-dragged > * {
  opacity: 0;
}
.bucket-dragged {
  background-color: rgb(255, 89, 89);
  opacity: 0.5;
  animation: shake 0.8s infinite;
}
.bucket-dragged > * {
  opacity: 0;
}
.bucket-buttons {
  visibility: hidden;
}
.bucket-title:hover .bucket-buttons {
  visibility: visible;
}
@keyframes glowing {
  0% {
    box-shadow: 0 0 0px #414040;
  }
  40% {
    box-shadow: 0 0 5px #414040;
  }
  60% {
    box-shadow: 0 0 0px #414040;
  }
  100% {
    box-shadow: 0 0 0px #414040;
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.modal-header .close {
  color: white;
}
