.card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  width: 250px;
  height:100px;
  margin-top: 100px;
  margin-right: 10px;
  border-radius: 10px;
  text-align: center;
  padding-top: 30px;
}
