/******************** Lists ************************/
.Card {
  position: relative;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 rgba(9, 45, 66, 0.25);
  font-size: 15px;
  min-height: 100%;
}

.Card:hover {
  background: #F4F5F7;
  visibility: visible;
}



.bucket_container {
  margin-top: 10px !important;
  margin-right: 0px !important;
  height: 82vh;

}

.horizontal-scrollable {
  overflow-x: auto;
  overflow-y: hidden;
}


.horizontal-scrollable>.row>.col-xs-3 .list {
  display: inline-block;
  float: none;
}


.list-items::-webkit-scrollbar {
  width: 1rem;
}

.list-items::-webkit-scrollbar-thumb {
  background-color: #c4c9cc;
  border-right: 0.6rem solid #e2e4e6;
}

.List {
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  position: relative;
  white-space: normal;
  width: 272px !important;
  background-color: #EBECF0;
}
/* .list-items {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: start;
  padding: 0 0.6rem 0.5rem;
  min-height: 55vh;
  overflow-y: auto;
} */

.list:last-of-type {
  margin-right: 0;
}

.list-title {
  font-size: 17px;
  font-weight: 700;
  color: #333;
  padding: 1rem;


}

.list-items {
  flex: 1 1 auto;
    margin: 0 4px;
    min-height: 30vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1px 4px 0;
    z-index: 1;
}



.list-items li,
textarea {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  background-color: white;
  padding: 0.65rem 0.6rem;
  color: #000000;
  border-bottom: 0.1rem solid #ccc;
  border-radius: 0.3rem;
  margin-bottom: 0.6rlem;
  word-wrap: break-word;
  cursor: pointer;
}

.list-items li:last-of-type,
.list-items textarea:last-of-type {
  margin-bottom: 0;
}

.card_css {
  border-radius: 5px;
}

/* 
.list-items li:hover {
  background-color: #f0f0f0;
} */


.row>* {
  flex-shrink: 0;
  width: 22%;
  max-width: 100%;
}


.list:last-of-type {
  margin-right: 0;
}



/* .list-items li,
textarea {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
  background-color: #f8f9fa;
  padding: 0.65rem 0.6rem;
  color: #000000;
  border-bottom: 0.1rem solid #ccc;
  border-radius: 0.3rem;
  margin-bottom: 0.6rem;
  word-wrap: break-word;
  cursor: pointer;

.list-items li:last-of-type {
  margin-bottom: 0;
}

.list-items li:hover {
  background-color: #eee;
}   */

.add-card-btn {
  display: block;
  width: 272px;
  font-size: 15px;
  font-weight: 400;
  color: #4d4d4d;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  border: none;
}

.add-card-btn:hover {
  background-color: #DADBE2;
  color: #4d4d4d;
  text-decoration: none;
}

li .edit-button {
  display: none;
}

li:hover .edit-button {
  display: inline-block;
}

@supports (display: grid) {
  .list {
    display: grid;
    grid-template-rows: auto minmax(auto, 1fr) auto;
  }

  .list-items {
    display: grid;
    grid-row-gap: 0.6rem;
  }
}

.add-card-btncross {
  align-items: center;
  background-color: #0079bf;
  border: 1px solid #0079bf;
  border-radius: 0.25rem;
  box-shadow: #0079bf 0 1px 3px 0;
  color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  transition: all 250ms;
}

.list .list-header {
  flex: 0 0 auto;
  margin-bottom: -10px;
  min-height: 20px;
  position: relative;

}


.kanban__container {
  border-radius: 3px;
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  margin-right: 5px;
  max-width: 300px;
  min-height: 20px;
  overflow-y: auto;
  position: relative;
}
 

.dropdown-menu.show {
  max-height: 65vh;
  /* overflow: scroll; */
  overflow-x: hidden;
}