.description_box {
    background-color: #091e420a;
    border: none;
    font-size: 0.885rem;
    border-radius: 3px;
    box-shadow: none;
    display: inline-block;
    margin-left: 8px;
    margin-top: 14px;
    padding: 6px 12px 6px 6px;
    position: relative;
    text-decoration: none;
}

.pop_section {
    background-color: rgb(228 230 235);
    border: none;
    font-size: 0.885rem;
    border-radius: 3px;
    box-shadow: none;
    display: inline-block;
    margin-bottom: 8px;
    margin-left: 8px;
    padding: 6px 12px 6px 6px;
    position: relative;
    text-decoration: none;
}


@media (max-width: 767px) {
    .description_box {
        background-color: #091e420a;
        border: none;
        font-size: 0.885rem;
        border-radius: 3px;
        box-shadow: none;
        display: inline-block;
        margin-left: 8px;
        margin-top: 14px;
        padding: 6px 12px 6px 6px;
        position: relative;
        text-decoration: none;
        width: 100px;
    }



}