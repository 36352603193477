.add-tag-css{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: space-between;
    width: 100%;
}
.add-tags-input-field{
    width: 70%;
}
.add-tag-button-css{
    width: 28%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
}
.label-tag-css{
    display: flex;
    flex-wrap: wrap;
    /* padding: 15px; */
    /* justify-content: space-between; */
    /* width: 500px;  */
    margin-left: 15px;
    margin-top: 5px;
}
.label-tag-button-css{
    width: 30%;
    display: flex;
    flex-wrap: wrap;
}
.label-tag-button-css .delete-button{
    margin-left: 5px;
    opacity: 0;

}
.label-tag-button-css:hover .delete-button{
    opacity: 1;
}

