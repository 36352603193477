.mainContent{
padding: 20px;
}
.tableFixHead {
  overflow: auto;
  height: 100%;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgb(215, 215, 219);
}
.tableFixHead tbody th {
  position: sticky;
  left: 0;
}
.table td,
.table th {
  padding: 0.5rem !important;
  vertical-align: top;
  font-size: 0.9rem;
}
.btn__font {
  font-size: 0.9rem;
  padding: 0.175rem 0.5rem;
}

 /* pagination */
 .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;

    li {
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;

        a {
            padding: 8px 12px;
            display: block;
            text-decoration: none;
            color: #333;
            border: 1px solid #ccc;
            border-radius: 4px;

            &:hover {
                background-color: #5c84cf;
            }
        }

        &.active {
            background-color: #007bff;
            color: #fff;
            border: 1px solid #007bff;
        }
    }

    .previous,
    .next {
        margin: 0 5px;
        cursor: pointer;

        a {
            padding: 8px 12px;
            display: block;
            text-decoration: none;
            color: #333;
            border: 1px solid #ccc;
            border-radius: 4px;

            &:hover {
                background-color: #5c84cf;
            }
        }
    }
}

