.list-card-title{
    word-wrap: break-word;
    clear: both;
    color:#172b4d;
    display: block;
    margin: 0 0 4px;
    overflow: hidden;
    text-decoration: none;
    margin-top: 5px;
    margin-bottom:5px;
}

.list-bottom-icons-label{
    column-gap: 5px;
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
}

.list_pop_section {
    background-color: rgb(228 230 235);
    border: none;
    font-weight: 500;
    font-size: 0.70rem;
    border-radius: 3px;
    box-shadow: none;
    display: inline-block;
    margin-bottom: 8px;
    /* margin-left: 8px; */
    padding: 6px 6px 6px 6px;
    position: relative;
    text-decoration: none;
}

.popup_circle_view {
    width: 25px !important;
    height: 25px !important;
    line-height: 25px;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    text-align: center;
    background: #006476;
    display: inline-block;
  }