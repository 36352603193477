.List {
  background: #ebecf0;
  flex-shrink: 0;
  width: 272px;
  height: fit-content;
  margin: 10px;
  margin-right: 0;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.Lists-Cards{
  overflow-y: auto;
  margin-right: 5px;
}

.List-Title {
  /* cursor: pointer;
  padding: 10px;
  overflow-wrap: break-word;
   */
   flex: 0 0 auto;
    min-height: 20px;
    padding: 10px 8px;
    position: relative;
}

.Toggle-Add-Card {
  position: relative;
  cursor: pointer;
  padding: 10px;
  color: #6b808c;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
}

.Toggle-Add-Card:hover {
  background-color: rgba(9, 45, 66, 0.13);
  color: #17394d;
}

.Toggle-Add-Card ion-icon {
  margin-right: 2px;
}


.list-dropdown-container {
  position: absolute;
 float: right;
 text-align: right;
}

.list-dropdown-button{
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
}

.list-dropdown-menu {
  position: relative;
  top: 5px;
  left: -100px;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  z-index: 10;
  background-color: white;
  transition: max-height 0.2s ease-out;
}

.list-dropdown-menu.show {
  max-height: 200px; /* or any other desired height */
}



.dropdown-container {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 5px;
}
.dropdown-container .list-dropdown{
  border: none;
  background-color: transparent;
  font-weight: bold;
}

.dropdown-container .list-dropdown-close{
  position: absolute;
  border: none;
  background-color: transparent;
  right: 10px;
  top: 5px;
}

.dropdown-content {
  position: absolute;
  z-index: 1;
  background-color: #f9f9f9;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  border-radius: 5px;
  left: 10px;
}

.dropdown-content h6 {
  text-align: center;
}
