/* .cjm {
  overflow-x: auto;
  overflow-y: hidden;
} */

.cjm .lane {
  height: 130px;
}

.cjm .lane .lanename {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  /* word-break: break-all; */
  overflow: hidden;
  /* box-shadow: 1px 5px 10px 5px #aaa; */
  margin-right: 5px;
  padding: 10px;
}

.cjm .nodes {
  padding: 10px 0px;
  display: flex;
}
.cjm .cardlane-node {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 3; */
  text-overflow: ellipsis;
  overflow: hidden;

  align-items: center;
  justify-content: center;
  /*  white-space: pre-wrap; CSS3 */
  /* white-space: -moz-pre-wrap;  Firefox */
  /*white-space: -pre-wrap;  Opera <7 */
  /*white-space: -o-pre-wrap;  Opera 7 */

  /* word-break: break-all; */

  width: 200px;
  height: 110px;
  background-color: #20b2aa;
  color: #fff;
  padding: 5px;
  padding-right: 25px;

  border-radius: 5px;
}
.cjm .bubblelane-node {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 3; */
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
  /*  white-space: pre-wrap; CSS3 */
  /* white-space: -moz-pre-wrap;  Firefox */
  /*white-space: -pre-wrap;  Opera <7 */
  /*white-space: -o-pre-wrap;  Opera 7 */

  /* word-break: break-all; */

  width: 200px;
  height: 110px;
  background-color: #20b2aa;
  color: #fff;

  /* box-shadow: 5px 5px 6px #888; */
  border-radius: 5px;
  padding: 5px;
  padding-right: 25px;

  position: relative;
}
.cjm .phaselane-node {
  display: -webkit-box;
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;

  overflow: hidden;

  align-items: center;
  justify-content: center;
  /*  white-space: pre-wrap; CSS3 */
  /* white-space: -moz-pre-wrap;  Firefox */
  /*white-space: -pre-wrap;  Opera <7 */
  /*white-space: -o-pre-wrap;  Opera 7 */

  /* word-break: break-all; */

  width: 200px;
  height: 65px;
  /* border-radius: 0px 30px 30px 0px; */
  border-radius: 5px 0px 0px 5px;
  background-color: #20b2aa;
  color: #fff;
  box-shadow: 6px #888;
  position: relative;
  padding: 5px;
  padding-right: 25px;
}

.cjm .textlane-node {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 3; */
  text-overflow: ellipsis;

  overflow: hidden;

  align-items: center;
  justify-content: center;
  /*  white-space: pre-wrap; CSS3 */
  /* white-space: -moz-pre-wrap;  Firefox */
  /*white-space: -pre-wrap;  Opera <7 */
  /*white-space: -o-pre-wrap;  Opera 7 */

  /* word-break: break-all; */
  overflow-y: hidden;

  width: 200px;
  height: 110px;

  background-color: transparent;
  color: black;
  /* box-shadow: 5px 5px 6px #888; */
  position: relative;
  padding: 5px;
  padding-right: 25px;
}
.cjm .filelane-node {
  display: flex;
  width: 200px;
  height: 110px;
  align-items: center;
  justify-content: center;
  /* word-break: break-all; */
  border-radius: 5%;
  padding: 5px;
}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 33px solid transparent;
  margin-right: -30px;
  border-bottom: 32px solid transparent;
  z-index: 3;
}
.resizer {
  width: 10px;
  height: 65px;
  border-right: 2px dashed grey;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: e-resize;

  opacity: 0;
}
.res {
  width: 10px;
  height: 65px;
}

.cjm .phaselane-node:hover .resizer {
  opacity: 1;
}
.icon-box {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0px 5px;

  margin-left: -30px;
  top: 0;
  z-index: 3;
}
.comment-icon {
  margin-top: 40px;
  margin-left: -20px;
  padding-right: 5px;
  z-index: 3;
}
.cjm .addnode:hover {
  opacity: 1;
}
.cjm .addnode {
  cursor: pointer;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  width: 197px;

  margin-left: 52px;
  background-color: transparent;
  border: 2px dashed grey;
  border-radius: 5%;
  color: grey;
}

.cjm .addnode-middle {
  width: 40px;
  margin: 0px 5px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed grey;
  cursor: pointer;
  opacity: 0;
  border-radius: 5%;
  color: grey;
  z-index: 1;
  margin-left: 34px;
}
.cjm .addnode-middle:hover {
  opacity: 1;
}

.triangle-left-sm {
  width: 0;
  height: 0;
  left: 0;
  right: 0;
  margin-top: 40px;
  margin-left: -26px;
  border-top: 13px solid transparent;

  border-bottom: 13px solid transparent;
  z-index: 99;
  position: relative;
}
.shift {
  position: absolute;
  cursor: pointer;
  margin-left: -165px;

  opacity: 0;
  font-size: 23px;
}
.cjm .parentlane:hover .shift {
  opacity: 0.7;
}
.cjm .cardlane:hover .shift {
  opacity: 0.7;
}
.shiftdown {
  margin-right: 3px;
}
.shiftdown:hover {
  opacity: 1;
}
.cjm .deleteicon {
  bottom: 0;
  margin-top: 80px;
  margin-left: -20px;
  padding-right: 5px;
  opacity: 0;
  z-index: 4;
}
.cjm .parent {
  display: flex;
}
.cjm .parentlane {
  display: flex;
}
.cjm .parent:hover .deleteicon {
  opacity: 1;
}
.cjm .deletelaneicon {
  opacity: 0;
}
.cjm .parentlane:hover .deletelaneicon {
  opacity: 1;
}
.__react_modal_image__modal_container {
  z-index: 10000 !important;
}
.modalsmallimage {
  max-width: 200px !important;

  max-height: 110px !important;
}
.react-expand-collapse__button {
  color: #59595a;
  bottom: unset;
  right: unset;
  background-color: unset;
  top: 5px !important;
  left: 10px !important;
}
