 @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&family=Mulish:wght@300&family=Nunito+Sans:wght@300&family=Trirong:wght@400;500&display=swap');

* {
    font-family: Mulish, serif;
}
body {
    margin: 0;

    font-family: Mulish, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Mulish, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-contexify {
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%) !important;
}
.react-contexify {
	z-index: 1000 !important;
}