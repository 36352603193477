/* YourComponent.css */

.your-component {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
}

.card-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.card_of_route {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  width: 250px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
justify-content: space-between;
border-radius: 6px;
padding: 10px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.popup-trigger {
  cursor: pointer;
   color: #333;
   font-size: 20px;
     transition: color 0.3s ease-in-out;
}

.popup-trigger:hover {
    color: #007bff;
}

.popup {
  position: absolute;
      top: 115%;
    left: 100%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px 0;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 150px;
  display: flex;
}
.card_of_route p{
  margin-bottom: 0;
}
.popup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

      width: 100%;
}

.popup li {
  font-size: 0.85rem;
  
  padding: 5px 15px;
    width: 100%;
  display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #000;
}
.popup li:hover{
  background-color: dodgerblue;
  color: #fff;
}

.popup a {
  text-decoration: none;
  color: #333;
}
.popup a:hover{
  color: #fff;
}
