:root {
  --ck-inner-shadow: none !important;
  --ck-drop-shadow: none !important;
  --ck-drop-shadow-active: none !important;
  --ck-color-switch-button-inner-shadow: none !important;
  --ck-focus-outer-shadow-geometry: none !important;
  --ck-focus-outer-shadow: none !important;
  --ck-focus-disabled-outer-shadow: none !important;
  --ck-focus-error-outer-shadow: none !important;
  --ck-focus-ring: none;
}

.c-pointer {
  cursor: pointer;
}
.personacontainer {
  margin-top: 90px;
}
.personacontainer .form-group {
  padding: 1em;
}
.personacontainer .form-control {
  padding: 0px;
}

.personacontainer label {
  color: #212520cc !important;
  margin-bottom: 0;
}

.personacontainer input {
  padding-left: 0px !important;
  padding: 10px 0px !important ;
  background-color: transparent !important;
}
.personacontainer textarea {
  padding-left: 0px !important;
  padding: 10px 0px !important ;
  background-color: transparent !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}
html {
  overflow-y: scroll;
}

.custom-file-container {
  position: relative;
  width: 100%;
}

.custom-file {
  display: none;
  position: absolute;
  z-index: 3;
}

.label-custome-file {
  position: absolute;
  width: inherit;
  height: inherit;
  /* background-color: #f8f9fa; */
  z-index: 0;
}

.custom-select {
  background-color: #efeff0;
  border: 0px;
}

::-webkit-scrollbar:vertical {
  display: none;
}
.feild {
  position: relative;
  height: inherit;
}

.colorpicker-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  border: none;
  cursor: pointer;
}
.colorpicker {
  cursor: pointer;
  outline: none;
  border: none;
  padding: none;
  height: 100%;
  width: 100%;
}
::-moz-color-swatch {
  border-color: transparent !important;
}
::-moz-color-swatch:hover,
::-moz-color-swatch:active,
::-moz-color-swatch:visited {
  border-color: transparent;
}
.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable),
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
  padding: 0px;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
.persona-img {
  max-width: 100%;
  max-height: 100%;
}

/*================== New Styles ================*/
label.required:after {
  padding-left: 5px;
  color: #cb2431;
  content: "*";
}

.card-node {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 3; */
  overflow: auto;
  cursor: pointer;
  margin-left: 5px;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  /* word-break: break-all; */
  width: auto;
  height: auto;
  max-width: 500px;
  min-width: 200px;
  max-height: 150px;
  background-color: #20b2aa;
  color: #fff;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.addnode:hover {
  opacity: 1;
}
.addnode {
  cursor: pointer;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 110px;
  margin-left: 8px;
  background-color: transparent;
  border: 2px dashed grey;
  border-radius: 5%;
  color: grey;
}

.persona-template-card {
  background: rgb(211, 211, 211);
  width: 180px;
  height: 200px;
  overflow: hidden;
}

/* --------------- Context Menu ------------------------- */
.context-menu {
  background-color: rgb(255, 255, 255);
  border-radius: 4%;
  width: 8%;
  font-size: 1.2em;
  box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.context-menu-item {
  padding: 3%;
}
.context-menu-item:hover {
  cursor: pointer;
  background-color: #007bff;
  color: white;
}
