.add-tag-css{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: space-between;
    width: 100%;
}
.add-tags-input-field{
    width: 70%;
}
.add-tag-button-css{
    width: 28%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
}

.team-image-css{
    width: 100%;
    display:flex;
    flex-wrap: wrap;

}

.team-image-box {
    position: relative; /* Set the parent container to relative position */
    width: 250px; /* Adjust the width as needed */
    height: 250px; /* Adjust the height as needed */
    padding: 5px;
  }
  
  img {
    width: 100%;
     
    /* object-fit: cover;  */
  }
  
  .button-on-image {
    position: absolute; /* Set the button to absolute position */
    bottom: 10px; /* Position the button at the bottom */
    right: 15px; /* Position the button at the left */
    /* background-color: rgba(0, 0, 0, 0.5);  */
    color: white; /* Button text color */
    padding: 5px 10px; /* Adjust padding as needed */
    opacity: 0;
    border-radius: 5px;
    border: none;
    
  }


.button-on-image:hover {
    background-color: red; 
    opacity: 1;
} 