.label_input {
  outline: none;
  border: none;
  box-sizing: border-box;
  color: #172b4d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  transition-duration: 85ms;
  transition-timing-function: ease;
  border-radius: 3px;
  padding: 8px 12px;
  box-shadow: inset 0 0 0 2px #dfe1e6;
  background-color: #fafbfc;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}

.label_input:focus {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 2px #0079bf;
}

label {
  color: #5e6c84;
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 4px;
  margin-top: 12px;
}

.label_ul {
  padding: 4px 0 8px;
}

.label_ul .label_1 {
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 4px 4px;
  width: 92%;
  cursor: pointer;
}

.label_ul .label_01 {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-top: 6px;
  padding-right: 8px;
  padding-bottom: 6px;
}

.check_main {
  position: relative;
  flex-shrink: 0;
  border-radius: 2px;
  height: 16px;
  width: 16px;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  background-color: #fafbfc;
}

.input_checkbox {
  position: relative;
  flex-shrink: 0;
  border-radius: 2px;
  height: 16px !important;
  width: 16px !important;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 15px;
  transition: all 0.2s ease-in-out;
  background-color: #fafbfc;
  margin-top: 0 !important;
}

.label_top_span {
  /* flex-grow: 1;
  margin-left: 12px;
  width: 200px;
  overflow: hidden; */
  width: 200px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

span .label_div {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 4px;
  margin-bottom: 4px;
  min-width: 0;
}

.pd_left {
  padding-left: 32px;
}

.label01_div_text {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  border-radius: 3px;
  padding: 0 12px;
  max-width: 100%;
  min-width: 48px;
  height: 32px;
  box-sizing: border-box;
  /* background-color: #dfe1e6; */
  line-height: 32px;

  font-size: 14px;
  font-weight: 400;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label_wh {
  width: 100%;
}

.label_ul li:hover {
  background-color: #f0f0f000;
}

.label_ul li {
  border-bottom: none;
  margin-bottom: 0;
  padding: 0 0.6rem;
}


/* .label_color {
  background-color: #faf3c0;
  color: #131313;
  color: #172b4d;
}  */
/* .pd_left::before {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 8px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #f5dd29;
}
.pd_left1::before {
  background-color: #f5dd29;
} */
.lable__pen {
  flex-shrink: 0;
  margin-bottom: 0;
}

.lable__pen_icon {
  color: #172b4d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  white-space: normal;
  box-shadow: none;
  margin-top: 12px;
  border: none;
  margin-right: 11px;
}

.label_btn_wh {
  width: 100%;
}

.remove_btn {
  width: 100%;
  background: #dcdfe4;
}

.label_btn {
  color: #172b4d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 6px 12px;
  text-decoration: none;
  white-space: normal;
  background-color: rgba(9, 30, 66, 0.04);
  box-shadow: none;
  border: none;
  transition-duration: 85ms;
  transition-timing-function: ease;
}

@media (max-width:460px) {

  .labelpopup-shadow {
    right: 30px !important;
  }
}

/*============ edit label pop css =========== */
.show_color {
  padding: 32px;
  background-color: #F4F5F7;
}

.colorBox {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
  margin-bottom: 8px;
  margin: 0 10px 0;
}

.colorBox_section {
  position: relative;
  box-sizing: border-box;
  border-radius: 3px;
  border: 2px transparent solid;
  height: 32px;
  width: 54px;
}

.section_box {
  position: relative;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.bg1 {
  background-color: #B7DDB0;
}

.bg2 {
  background-color: #FAD29C;
}

.bg3 {
  background-color: #EFB3AB;
}

.bg4 {
  background-color: #DFC0EB;
}

.bg5 {
  background-color: #7BC86C;
}

.bg6 {
  background-color: #F5DD29;
}

.bg7 {
  background-color: #FFAF3F;
}

.bg8 {
  background-color: #EF7564;
}

.bg9 {
  background-color: #CD8DE5;
}

.bg10 {
  background-color: #5AAC44;
}

.bg11 {
  background-color: #E79217;
}

.bg12 {
  background-color: #E6C60D;
}

.bg13 {
  background-color: #CF513D;
}

.bg14 {
  background-color: #A86CC1;
}

.bg15 {
  background-color: #8BBDD9;
}

.bg16 {
  background-color: #8BBDD9;
}

.bg17 {
  background-color: #F9C2E4;
}

.bg18 {
  background-color: #505F79;
}

.bg19 {
  background-color: #5BA4CF;
}

.bg20 {
  background-color: #29CCE5;
}

.bg21 {
  background-color: #6DECA9;
}

.bg22 {
  background-color: #FF8ED4;
}

.bg23 {
  background-color: #344563;
}

.bg24 {
  background-color: #026AA7;
}

.bg25 {
  background-color: #00AECC;
}

.bg26 {
  background-color: #4ED583;
}

.bg27 {
  background-color: #E568AF;
}

.bg28 {
  background-color: #091E42;
}

.bg29 {
  background-color: #505F79;
}

.bg30 {
  background-color: #CF513D;
}