#outline {
  stroke-dasharray: 2.42776657104px, 242.776657104492px;
  stroke-dashoffset: 0;
  animation: anim 1.6s linear infinite;
}
@keyframes anim {
  12.5% {
    stroke-dasharray: 33.9887319946px, 242.776657104492px;
    stroke-dashoffset: -26.7054322815px;
  }
  43.75% {
    stroke-dasharray: 84.9718299866px, 242.776657104492px;
    stroke-dashoffset: -84.9718299866px;
  }
  100% {
    stroke-dasharray: 2.42776657104px, 242.776657104492px;
    stroke-dashoffset: -240.348890533px;
  }
}
