.form_div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input_div {
    display: flex;
    align-items: center;
    gap: 20px;
}
.input_field_text {
    width: 100%;
    height: 35px;
    border: 1px solid black;
    border-radius: 6px;
    padding: 0px 10px;
    text-transform: capitalize;
}
.input_field {
    width: 100%;
    height: 35px;
    border: 1px solid black;
    border-radius: 6px;
    padding: 0px 10px;
}
.inputarea_field {
    border: 1px solid black;
    border-radius: 6px;
    padding: 0px 10px;
}
.button_div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
.form_cancel_button {
    padding: 5px 15px;
    border: none;
    border-radius: 6px;
    background-color: #adb5bd42;
    color: #292424;
}
.form_button {
    padding: 5px 15px;
    border: none;
    border-radius: 6px;
    background-color: blue;
    color: #fff;
}
.comment_icons_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #adb5bd42;
    gap: 5px;
    margin: 15px 5px 5px 0px;
    cursor: pointer;
}
.comments_of_user {
    padding: 20px 30px;
    border: 1px solid #e9ecef;
    border-radius: 6px;
}
.comments_of_user p {
    margin-bottom: 0;
}
.name_p {
    line-height: 0;
    text-transform: capitalize;
    font-weight: 800;
}
.comments_of_user .email_span {
    font-size: 0.75rem;
    color: #adb5bd;
}

.like_icons_div {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    color: blue;
    font-size: 0.85rem;
    cursor: pointer;
    margin-bottom: 20px;
}
.reply_comment {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 20px 0;
}
.date_of_comment {
    font-size: 0.75rem;
    color: #adb5bd;
    text-transform: capitalize;
}
.comment_message {
    margin-top: 10px;
    margin-bottom: 0;
    background-color: #adb5bd42;
    width: 400px;
    border-radius: 0px 6px 6px 6px;
    padding: 8px;
    font-size: 0.85rem;
}
.viewMoreBtn {
    padding: 5px 10px;
    border: 1px solid #adb5bd;
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.viewMoreBtn:hover {
    background-color: #adb5bd;
}
@media screen and (max-width: 1000px) {
    .comment_message {
        width: 300px;
    }
}
@media screen and (max-width: 480px) {
    .comment_message {
        width: 265px;
    }
    .name_p {
        font-size: 0.85rem;
    }
}
