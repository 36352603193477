/* Set dropdown font-families */
.ql-font span[data-value="impact"]::before, .ql-font span[data-label="Impact"]::before {
    font-family: "Impact";
  }
  .ql-font span[data-value="courier"]::before, .ql-font span[data-label="Courier"]::before {
    font-family: "Courier";
  }
  .ql-font span[data-value="comic"]::before, .ql-font span[data-label="Comic Sans MS"]::before {
    font-family: "Comic Sans MS";
  }
  
  .ql-font span[data-value="Roboto"]::before, .ql-font span[data-label="Roboto"]::before {
    font-family: "Roboto";
  }

  .ql-font span[data-value="Verdana"]::before, .ql-font span[data-label="Verdana"]::before {
    font-family: "Verdana";
  }
  
  .ql-font span[data-value="Ubuntu"]::before, .ql-font span[data-label="Ubuntu"]::before {
    font-family: "Ubuntu";
  }
  
  .ql-font span[data-value="Times New Roman"]::before, .ql-font span[data-label="Times New Roman"]::before {
    font-family: "Times New Roman";
  }
  
  .ql-size span[data-value="12px"]::before, .ql-size span[data-label="12px"]::before {
    font-size: 12px;
  }
  .ql-size span[data-value="24px"]::before, .ql-size span[data-label="24px"]::before {
    font-size: 24px;
  }
  .ql-size span[data-value="10px"]::before, .ql-size span[data-label="10px"]::before {
    font-size: 10px;
  }
  .ql-size span[data-value="14px"]::before, .ql-size span[data-label="14px"]::before {
    font-size: 14px;
  }
  .ql-size span[data-value="16px"]::before, .ql-size span[data-label="16px"]::before {
    font-size: 16px;
  }
  .ql-size span[data-value="18px"]::before, .ql-size span[data-label="18px"]::before {
    font-size: 18px;
  }
  .ql-size span[data-value="20px"]::before, .ql-size span[data-label="20px"]::before {
    font-size: 20px;
  }
  .ql-size span[data-value="22px"]::before, .ql-size span[data-label="22px"]::before {
    font-size: 22px;
  }
  
  .ql-font-impact { font-family: 'Impact'}
  .ql-font-courier { font-family: 'Courier'}
  .ql-font-comic { font-family: 'Comic Sans MS'; color: black;}
  .ql-font-roboto { font-family: 'Roboto'; color: black;}
  .ql-font-verdana { font-family: 'Verdana'; color: black;}
  .ql-font-ubuntu { font-family: 'Ubuntu'; color: black;}
  .ql-font-times-new-roman { font-family: 'Times New Roman'; color: black;}
  .ql-size-12px { font-size: 12px; color: black;}
  .ql-size-24px { font-size: 24px; color: black;}
  .ql-size-24px { font-size: 14px; color: black;}
  .ql-size-24px { font-size: 16px; color: black;}
  .ql-size-24px { font-size: 10px; color: black;}
  .ql-size-24px { font-size: 18px; color: black;}
  .ql-size-24px { font-size: 20px; color: black;}
  .ql-size-24px { font-size: 22px; color: black;}
  


.ql-video {
    display: block;
    max-width: 100%;
    height: 1000px;
}