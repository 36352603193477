.chat {
    position: fixed;
    bottom: 20px;
    right: 50px;
    display: flex;
}
.chat_container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1px solid black;
    width: 300px;

    border-radius: 8px;
    background-color: #efeae2;
}
.chat_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    background: #00a884;
}
.chat_messages {
    margin: 10px 0px;
    background-color: #efeae2;
    width: 80%;
    overflow-y: auto;
    margin-left: 250px;
    border-radius: 8px;
    padding: 20px;
}
.chat_messages::-webkit-scrollbar-thumb {
    width: 5px;
}
.chat_messages::-webkit-scrollbar {
    width: 5px;
}
.input_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 8px 8px;
    width: 100%;
    height: 50px;
    padding: 10px;
    background: #f0f2f5;
}
.bot_user {
    display: flex;
    align-items: center;
    gap: 5px;
}
.bot_name {
    margin-bottom: 0;
    font-size: 1rem;
    color: #fff;
    line-height: 120%;
}
.bot_work {
    font-size: 0.7rem;
    color: #fff;
}
.bot_info_div {
    display: flex;
    flex-direction: column;
}
.input_container input {
    background-color: transparent;
    color: #000;
    padding: 5px 10px;
    border: none;
    background-color: #fff;
    border-radius: 6px;
}
.input_container input:active {
    border: none;
}
.input_container input:focus {
    outline: none;
}
.user_comment_message {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
}
.comment_message {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}
.message {
    background-color: #fff;
    border-radius: 0px 8px 8px 8px;
    width: max-content;
    padding: 10px;
    font-size: 0.8rem;
    width: fit-content;
    max-width: 250px;
    font-weight: 600;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.message_user {
    background-color: #d9fdd3;
    border-radius: 8px 0px 8px 8px;
    width: max-content;
    padding: 10px;
    font-size: 0.8rem;
    width: fit-content;
    max-width: 250px;
    font-weight: 600;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.message_user span{
    color: #b22424;
}
 

.p0s8B {
    position: absolute;
    top: 60px;
    z-index: var(--layer-1);
    display: block;
    width: 8px;
    height: 13px;
    left: 2px;
    color: #fff;
}
.date{
    color: #0079BF;
    font-weight: 600;
}
.bot_p{
color: #3819b7;
}
.p0s8Bs {
    position: absolute;
    top: 120px;
    z-index: var(--layer-1);
    display: block;
    width: 8px;
    height: 13px;
    right: 2px;
    color: #d9fdd3;
}
