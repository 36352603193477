.second_header {
  margin-left: -10px;
  background-color: #47b3f2;
  padding-left: 25px;
  padding-top: 5px;
  height: 50px;
  position: relative;


}

.board-header {
  background: #00000017;
}

.board-header {
  height: auto;
  padding: 8px 4px 4px 12px;
}

.u-clearfix:after {
  clear: both;
  content: "";
  display: table;
}

/* .board-header.collapsed-workspace-nav {
  padding-left: 25px;
}

.board-header-btn.mod-board-name {
  background: #0000;
  cursor: default;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  max-width: calc(100% - 24px);
  overflow: hidden;
  padding: 0;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
} */


.board-header-btn {
  border-radius: 3px;
  color: #ffffff;
  background-color: rgb(255 255 255 / 40%);
  cursor: default;
  float: left;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  margin: 0 4px 4px 0;
  max-width: 400px;
  overflow: hidden;
  padding-left: 32px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
}

.board-header-btn:active {
  background-color: rgb(255 255 255 / 40%);
  color: #ffffff;
  cursor: pointer;
}

.board-header-btn:hover {
  color: #ffffff;
  cursor: pointer;
}

.board-header-btn.mod-board-name .board-header-btn-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.icon-sm {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  color: #42526e;
  display: inline-block;
  
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  text-decoration: none;
}

.board-header-btn-icon {
  background-clip: content-box;
  background-origin: content-box;
  color: currentColor;
  left: 0;
  /* padding: 6px; */
  /* position: absolute; */
  top: 0;
}

.board-header-btn-divider {
  border-left: 1px solid white;
  float: left;
  height: 16px;
  margin: 8px 8px 12px 4px;
}

.headerboard {
  display: inline-flex;
  white-space: nowrap;
  float: left;
  height: 32px;
  line-height: 32px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.headerboard_text {
  /* background-color: #dfe1e6; */
background-color: #47b3f2;
  color: #172b4d;
  flex-shrink: 0;
  padding: 0 12px 0 12px;
  user-select: none;
  border-radius: 3px;
  float: left;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  margin: 0 5px 4px 0;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  cursor: pointer;
}

.board-header-btns {
  display: block;
  position: relative;
}

.board-header-btns.mod-right {
  float: right;
}

.board-header-btns.mod-right .board-header-container {
  float: left;
}

.board-header-contain {
  display: flex;
  margin-right: 4px;
  margin-bottom: 4px;
}

.contain {
  /* min-width: 124px; */
  margin: 0px;
}

.contain__col {
  color: #172b4d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 6px 12px;
  text-decoration: none;
  white-space: normal;
  background-color: rgb(255 255 255 / 20%);
  box-shadow: none;
  border: none;
  color: #ffffff;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}

.contain__col2 {
  color: #172b4d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px 8px;
  text-decoration: none;
  white-space: normal;
  background-color: rgb(255 255 255 / 20%);
  box-shadow: none;
  border: none;
  color: #ffffff;
  /* transition-property: background-color, border-color, box-shadow; */
  /* transition-duration: 85ms;
  transition-timing-function: ease; */
}

.col_icon {
  display: inline-block;
  line-height: 1;
  margin-right: 8px;
}

.show-sidebar-button {
  display: block;
  float: left;
  position: relative;
}

.show-icon {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 6px;
  text-decoration: none;
  white-space: normal;
  color: #ffffff;
  background: rgb(255 255 255 / 20%);
}

.css_svg {
  overflow: hidden;
  pointer-events: none;
  max-width: 100%;
  max-height: 100%;
  vertical-align: bottom;
}

.board-header-facepile {
  cursor: default;
  float: left;
  /* margin-left: 4px; */
  overflow: hidden;
  /* padding: 2px 0 0 2px; */
  border-radius: 25em;
}

.namecircle-inner {
  cursor: pointer;
  float: left;
  text-align: center;
  align-self: center;
  color: white;
  font-size: 14px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 0px;
  overflow: hidden;
  border-radius: 50%;
}

.member-count {
  cursor: pointer;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  font-size: 13px;
  color: #fff;
  text-align: center;
  background: rgb(1, 24, 45);
}

.user-dropdown {
  width: 100%;
  padding: 10px;
  top: 3rem;
  right: 3rem;
  position: absolute;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  opacity: 0;
  max-height: 100%;
  overflow: hidden;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.215);
  transition: all 0.3s ease-out;
  z-index: 10;
}

.user-dropdown.open {
  opacity: 1;
  width: 50vh;
  height: 100vh;
  max-height: 320px;
  transition: all 0.3s ease-in;
}

.dropdown-close {
  background-color: #fff;
  font-size: 20px;
  padding: 10px 12px 10px 8px;
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;
  border: none;
  z-index: 2;
}

.is-editing .board-name-input {
  display: block;
}

.board-name-input {
  background-color: #fff;
  border: 0;
  display: none;
  font-size: 18px;
  font-weight: 700;
  height: 32px;
  margin: 0;
  padding: 0 12px;
}

input:focus-visible {
  outline-offset: 0px !important;
  outline: none !important;
}

input {
  border: none;
}

:focus {
  outline: none;
}



@media (max-width: 767px) {
  .second_header {
    margin-left: -30px;
  }
}