.createBoardStyle{
    width: 0%;
}
#create-board-popup p {

    
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #5e6c84;
    margin-bottom: 5px;
}

.CreateBoardPopup-create-btn {
    width: 100%;
    border: none !important;
}

.CreateBoardPopup-create-btn:hover {
    width: 100%;
    border: none !important;
    color: white !important;
    background-color: #0079bf !important;
}



.create-board-popup .border-0 {
    height: 90vh !important;
}

.Board-Title:after {
    content: " *";
    color: red;
}

.create-board-popup p{
margin-bottom: 0px;
}
.colorPicker {
    width: 46px;
    height: 34px;
    border-radius: 10px;
    border: 6px solid white;
}