.linelane-container {
  min-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 180px;
  scrollbar-width: none !important ;
  max-height: 180px;
  position: relative;
}

.linelane-node__container {
  min-width: 250px;
  position: relative;
}

.linelane-container ::-webkit-scrollbar {
  display: none;
}
.linelane-node__droppable {
  position: relative;
  width: 100%;
  height: 36px;
  overflow: hidden !important;
}

.node {
  z-index: 2;
  width: 36px;
  height: 36px;
  position: absolute;
  border-radius: 50%;
  /* background-color: mediumaquamarine; */
}

.c-pointer {
  cursor: pointer;
}

.add-node {
  z-index: 2;
  width: 36px;
  height: 36px;
  position: relative;
  border-radius: 50%;
  border: 2px rgb(48, 46, 46) dotted;
}

.linelane-container svg {
  position: absolute;
  z-index: 1;
}

.linelane-picker {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.158);
  width: 100%;
  height: 100%;
}
.exampleModal {
  z-index: 1000;
}

.linelane-container .emoji-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.linelane-container .emoji-container img {
  margin-left: 5px;
  cursor: pointer;
}

.wrapLine:before {
  content: "";
  position: absolute;
  /* top: 50%; */
  margin-top: 7.5%;
  left: 0;
  border-top: 0.5px solid rgb(225, 207, 207);
  min-width: 100%;
  transform: translateY(-50%);
}
